import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import Translator from '%app/js/common/services/translator';

function TranslatedTable({
    options,
    ...rest
}) {
    return (
        <MUIDataTable
            options={{
                textLabels: {
                    body: {
                        noMatch: Translator.trans('data_table.text_label.body.no_match'),
                        toolTip: Translator.trans('data_table.text_label.body.tooltip'),
                        columnHeaderTooltip: ({ label }) => (
                            Translator.trans('data_table.text_label.body.column_header_tooltip', { label })
                        ),
                    },
                    pagination: {
                        next: Translator.trans('data_table.text_label.pagination.next_page'),
                        previous: Translator.trans('data_table.text_label.pagination.previous_page'),
                        rowsPerPage: Translator.trans('data_table.text_label.pagination.rows_per_page'),
                        displayRows: Translator.trans('data_table.text_label.pagination.display_rows'),
                    },
                    toolbar: {
                        search: Translator.trans('data_table.text_label.toolbar.search'),
                        downloadCsv: Translator.trans('data_table.text_label.toolbar.download_csv'),
                        print: Translator.trans('data_table.text_label.toolbar.print'),
                        viewColumns: Translator.trans('data_table.text_label.toolbar.view_columns'),
                        filterTable: Translator.trans('data_table.text_label.toolbar.filter_table'),
                    },
                    filter: {
                        all: Translator.trans('data_table.text_label.filter.all'),
                        title: Translator.trans('data_table.text_label.filter.title'),
                        reset: Translator.trans('data_table.text_label.filter.reset'),
                    },
                    viewColumns: {
                        title: Translator.trans('data_table.text_label.view_columns.title'),
                        titleAria: Translator.trans('data_table.text_label.view_columns.title_aria'),
                    },
                    selectedRows: {
                        text: Translator.trans('data_table.text_label.selected_rows.text'),
                        delete: Translator.trans('data_table.text_label.selected_rows.delete'),
                        deleteAria: Translator.trans('data_table.text_label.selected_rows.delete_aria'),
                    },
                },
                ...options,
            }}
            {...rest}
        />
    );
}

TranslatedTable.propTypes = {
    options: PropTypes.object.isRequired,
};

export default React.memo(TranslatedTable);
