import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    CATEGORY_MARKETING,
    CATEGORY_NECESSARY,
    CATEGORY_STATISTICS,
} from '../../config/constants';
import Translator from '%app/js/common/services/translator';
import hasCookieCategoryConsent from '../../service/hasCookieCategoryConsent';
import addSelectedCategoriesConsent from '../../service/addSelectedCategoriesConsent';

function CookieConsentCategorySelection({ closeAgreementCard }) {
    const [checkboxValues, setCheckboxValues] = useState({
        [CATEGORY_NECESSARY]: true,
        [CATEGORY_STATISTICS]: hasCookieCategoryConsent(CATEGORY_STATISTICS),
        [CATEGORY_MARKETING]: hasCookieCategoryConsent(CATEGORY_MARKETING),
    });

    const handleAcceptSelected = useCallback(
        () => {
            const categories = [];
            Object.entries(checkboxValues).forEach(([categoryKey, checked]) => {
                if (checked) {
                    categories.push(categoryKey);
                }
            });
            addSelectedCategoriesConsent(categories);
            closeAgreementCard();
        },
        [checkboxValues],
    );

    const handleCheckboxChange = useCallback(
        (event) => {
            const checkboxName = event.target.name;

            const currentValue = checkboxValues[checkboxName];
            if (currentValue === undefined) {
                return;
            }

            setCheckboxValues({
                ...checkboxValues,
                [checkboxName]: event.target.checked,
            });
        },
        [checkboxValues],
    );

    return (
        <Grid container justify="space-between">
            <Grid item xs={7} sm={8} md={9}>
                <FormControlLabel
                    control={(
                        <Checkbox
                            size="small"
                            name={CATEGORY_NECESSARY}
                            checked
                            disabled
                        />
                    )}
                    label={(
                        <Typography variant="body2">
                            {Translator.trans('service_agreement_cookies.category.necessary')}
                        </Typography>
                    )}
                />
                <FormControlLabel
                    control={(
                        <Checkbox
                            size="small"
                            name={CATEGORY_STATISTICS}
                            onChange={event => handleCheckboxChange(event)}
                            checked={checkboxValues[CATEGORY_STATISTICS]}
                        />
                    )}
                    label={(
                        <Typography variant="body2">
                            {Translator.trans('service_agreement_cookies.category.statistics')}
                        </Typography>
                    )}
                />
                <FormControlLabel
                    control={(
                        <Checkbox
                            size="small"
                            name={CATEGORY_MARKETING}
                            onChange={event => handleCheckboxChange(event)}
                            checked={checkboxValues[CATEGORY_MARKETING]}
                        />
                    )}
                    label={(
                        <Typography variant="body2">
                            {Translator.trans('service_agreement_cookies.category.marketing')}
                        </Typography>
                    )}
                />
            </Grid>
            <Grid item xs={5} sm={4} md={3}>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={handleAcceptSelected}
                >
                    {Translator.trans('service_agreement_cookies.button.accept_selection')}
                </Button>
            </Grid>
        </Grid>
    );
}

CookieConsentCategorySelection.propTypes = {
    closeAgreementCard: PropTypes.func.isRequired,
};

export default React.memo(CookieConsentCategorySelection);
