import localizationStorage from '%app/js/common/services/localizationStorage';
import { DEFAULT_FONT_FAMILIES, RTL_FONT_FAMILY } from '../config/constants';

function getFontFamilies() {
    const defaultFontFamilies = DEFAULT_FONT_FAMILIES;

    if (localizationStorage.isCurrentLanguageRtl()) {
        defaultFontFamilies.unshift(RTL_FONT_FAMILY);
    }

    return defaultFontFamilies.join(',');
}

export default getFontFamilies;
