import { set } from 'lodash/object';
import {
    SORT_FILTER_TYPE_CUSTOM,
    SORT_DIRECTION_NONE,
    SORT_DIRECTION_DESC,
} from '../config/constants';

function resolveFilterFromState(state) {
    const nextFilter = {
        limit: state.rowsPerPage,
        offset: state.page * state.rowsPerPage,
    };
    const sort = [];

    Object.values(state.columns).forEach((column, key) => {
        if (column.filterType === SORT_FILTER_TYPE_CUSTOM) {
            if (column.filterOptions?.names) {
                state.filterList[key].forEach((filterValue, valueKey) => {
                    set(nextFilter, `${column.name}.${column.filterOptions.names[valueKey]}`, filterValue);
                });
            } else if (state.filterList[key].length > 0) {
                nextFilter[column.name] = state.filterList[key][0];
            }
        } else if (state.filterList[key].length === 1) {
            [nextFilter[column.name]] = state.filterList[key];
        }
        if (column.sort && column.sortDirection !== SORT_DIRECTION_NONE) {
            sort.push(`${column.sortDirection === SORT_DIRECTION_DESC ? '-' : ''}${column.name}`);
        }
    });

    if (sort.length > 0) {
        nextFilter.sort = sort.join(',');
    }
    if (state.searchText) {
        nextFilter.query = state.searchText;
    }

    return nextFilter;
}

export default resolveFilterFromState;
