import {
    CATEGORY_NECESSARY,
    CATEGORY_STATISTICS,
    CATEGORY_MARKETING,
} from '../config/constants';
import addSelectedCategoriesConsent from './addSelectedCategoriesConsent';

const addAllCategoriesConsent = () => {
    addSelectedCategoriesConsent([
        CATEGORY_NECESSARY,
        CATEGORY_STATISTICS,
        CATEGORY_MARKETING,
    ]);
};

export default addAllCategoriesConsent;
