import ensureTranslations from '%app/js/common/services/translationLoader';
import { render } from 'react-dom';
import Page from './component/Page';
import React from 'react';
import logger from '%app/js/common/services/Logger';

const mountDialogComponent = () => {
    const mountNodeId = 'base-react-root';
    const mountNode = document.getElementById(mountNodeId);
    if(!mountNode) {
        logger.error(`Element with ID ${mountNodeId} was not found in the DOM`);
        return null;
    }

    ensureTranslations('PayseraServiceAgreementBundle').then(() => {
        render(<Page/>, mountNode);
    });
};

window.addEventListener('load', mountDialogComponent);
