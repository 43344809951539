/**
 * @param {Array<Object>} stateColumns
 * @param {Array<Object>} propsColumns
 * @returns {Array<Object>}
 */
const resolveColumnsFromState = (propsColumns, stateColumns) => {
    if (stateColumns !== undefined) {
        return propsColumns.map((column, index) => {
            if (column.options.display !== 'excluded') {
                if (Object.prototype.hasOwnProperty.call(stateColumns[index], 'display')) {
                    // eslint-disable-next-line no-param-reassign
                    column.options.display = stateColumns[index].display === 'true';
                }
            }

            return column;
        });
    }

    return propsColumns;
};

export default resolveColumnsFromState;
