import moment from 'moment';
import { CONSENT_COOKIE_KEY } from '../config/constants';

const addSelectedCategoriesConsent = (categories) => {
    const date = moment().add(1, 'year');
    const secure = environment_config.environment === 'prod' ? ';secure' : '';

    document.cookie = `${CONSENT_COOKIE_KEY}=${encodeURIComponent(JSON.stringify(categories))};expires=${date.toString()};path=/${secure};SameSite=Lax`;
};

export default addSelectedCategoriesConsent;
