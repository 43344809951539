import React from 'react';
import '%app/js/common/services/translator';
import ensureTranslations from '%app/js/common/services/translationLoader';

const withTranslations = (domain, locales = []) => (WrappedComponent) => {
    class LoadTranslation extends React.PureComponent {
        constructor(props) {
            super(props);

            this.state = {
                loaded: false,
            };
        }

        componentDidMount() {
            ensureTranslations(domain, locales).then(() => {
                this.setState({
                    loaded: true,
                });
            });
        }

        render() {
            const { loaded } = this.state;
            return loaded && <WrappedComponent {...this.props} />;
        }
    }

    LoadTranslation.displayName = `LoadTranslation(${WrappedComponent.name})`;

    return LoadTranslation;
};

export default withTranslations;
