import Translator from '%app/js/common/services/translator';

const getStatisticsCookiesData = () => [
    {
        name: '_dc_gtm_UA-#',
        provider: 'paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._dc_gtm_ua'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._dc_gtm_ua'),
    },
    {
        name: 'gat_UA',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.gat_ua'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.gat_ua'),
    },
    {
        name: '_ga',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._ga'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._ga'),
    },
    {
        name: '_gcl_au',
        provider: 'paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._gcl_au'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._gcl_au'),
    },
    {
        name: '_gat',
        provider: 'paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._gat'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._gat'),
    },
    {
        name: '_gid',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._gid'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._gid'),
    },
    {
        name: 'collect',
        provider: 'google-analytics.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.collect'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.collect'),
    },
    {
        name: '_hjid',
        provider: 'paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._hjid'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._hjid'),
    },
    {
        name: '_hjTLDTest',
        provider: 'paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._hj_tld_test'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._hj_tld_test'),
    },
    {
        name: '_hjIncludedInPageviewSample',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._hj_included_in_pageview_sample'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._hj_included_in_pageview_sample'),
    },
    {
        name: '_hjAbsoluteSessionInProgress',
        provider: 'paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._hj_absolute_session_in_progress'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._hj_absolute_session_in_progress'),
    },
    {
        name: 'Session',
        provider: 'facebook.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.session'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.session'),
    },
    {
        name: '_lr_hb_-kroazc/tickets',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._lr_hb_kroazc_tickets'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._lr_hb_kroazc_tickets'),
    },
    {
        name: '_lr_tabs_-kroazc/tickets',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._lr_tabs_kroazc_tickets'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._lr_tabs_kroazc_tickets'),
    },
    {
        name: '_lr_uf_-kroazc',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._lr_uf_kroazc'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._lr_uf_kroazc'),
    },
];

export default getStatisticsCookiesData;
