import { CONSENT_COOKIE_KEY } from '../config/constants';

const hasCookieCategoryConsent = (category) => {
    const cookieConsentKeyValue = document.cookie
        .split('; ')
        .find(cookie => cookie.startsWith(CONSENT_COOKIE_KEY));

    if (cookieConsentKeyValue === undefined) {
        return false;
    }

    const consentCookieValue = cookieConsentKeyValue.split('=')[1];

    try {
        const parsedConsentCookieValue = JSON.parse(decodeURIComponent(consentCookieValue));

        return Array.isArray(parsedConsentCookieValue) ? parsedConsentCookieValue.includes(category) : false;
    } catch {
        return false;
    }
};

export default hasCookieCategoryConsent;
