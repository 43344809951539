import React from 'react';
import CookieAgreementCard from '../CookieAgreementCard';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { bootstrapAwareTheme } from '%evp_theme/brandbook/material/theme';
import configureTextDirection from '%app/js/common/components/material/service/configureTextDirection';

function Page() {
    return (
        <StylesProvider jss={configureTextDirection()}>
            <MuiThemeProvider theme={bootstrapAwareTheme}>
                <CookieAgreementCard />
            </MuiThemeProvider>
        </StylesProvider>
    );
}

export default React.memo(Page);
