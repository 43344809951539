import LocalizationStorage from '%app/js/common/services/localizationStorage';

const load = (domain, locales = []) => {
    const currentLanguage = LocalizationStorage.getCurrentLanguage();
    const fallbackLang = LocalizationStorage.getFallbackLanguage();
    if (!locales.includes(currentLanguage)) {
        locales.push(currentLanguage);
    }

    return Promise.all(
        locales.map((lang) => (
            import(`%web/js/translations/${domain}/${lang}.js`)
            .catch(() => import(`%web/js/translations/${domain}/${fallbackLang}.js`))
        )),
    );
};

const ensureTranslations = (domains, locales = []) => {
    if (Array.isArray(domains)) {
        return Promise.all(domains.map(domain => load(domain, locales)));
    }

    return load(domains, locales);
};

export default ensureTranslations;
