import Translator from 'bazinga-translator';

export default class TranslatorService {
    static trans(key, vars, domain, locale) {
        return Translator.trans(key, vars, domain, locale);
    }

    static transChoice(key, number, vars, domain, locale) {
        return Translator.transChoice(key, number, vars, domain, locale);
    }

    static add(key, value, domain) {
        return Translator.add(key, value, domain);
    }
}
