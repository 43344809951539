import React, { useCallback, useState } from 'react';
import Translator from '%app/js/common/services/translator';
import PropTypes from 'prop-types';
import {
    Collapse,
    Grid,
    Button,
    Typography,
} from '@material-ui/core';
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import Tabs from '../Tabs';
import CookieConsentCategorySelection from '../CookieConsentCategorySelection';
import addAllCategoriesConsent from '../../service/addAllCategoriesConsent';
import addNecessaryCategoriesConsent from '../../service/addNecessaryCategoriesConsent';
import { router } from '%app/js/common/services/router';

function Content({ classes, closeAgreementCard }) {
    const [collapseOpen, setCollapseOpen] = useState(false);

    const handleAcceptRecommended = useCallback(
        () => {
            addAllCategoriesConsent();
            closeAgreementCard();
        },
        [],
    );

    const handleAcceptOnlyNecessary = useCallback(
        () => {
            addNecessaryCategoriesConsent();
            closeAgreementCard();
        },
        [],
    );

    const toggleCollapse = () => {
        setCollapseOpen(!collapseOpen);
    };

    return (
        <Grid container className={classes.paper}>
            <Grid item xs={12}>
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                        __html: Translator.trans(
                            'service_agreement_cookies.body',
                            {
                                cookies_information_link: router.generate('evp_ticket_frontend.static_page.cookies', {}, true)
                            },
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="space-between">
                    <Grid item xs={4}>
                        <Button
                            color="secondary"
                            onClick={toggleCollapse}
                        >
                            {Translator.trans('service_agreement_cookies.button.customise')}
                            {!collapseOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={handleAcceptOnlyNecessary}
                        >
                            {Translator.trans('service_agreement_cookies.button.only_necessary')}
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAcceptRecommended}
                        >
                            {Translator.trans('service_agreement_cookies.button.accept_recommended')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={collapseOpen} className={collapseOpen ? '' : classes.hiddenCollapse}>
                    <CookieConsentCategorySelection closeAgreementCard={closeAgreementCard} />

                    <Tabs classes={classes} />
                </Collapse>
            </Grid>
        </Grid>
    );
}

Content.propTypes = {
    classes: PropTypes.shape({
        paper: PropTypes.string,
        hiddenCollapse: PropTypes.string,
        closeButton: PropTypes.string,
    }).isRequired,
    closeAgreementCard: PropTypes.func.isRequired,
};

export default React.memo(Content);
