import { get } from 'lodash/object';
import {
    SORT_FILTER_TYPE_CUSTOM,
    SORT_DIRECTION_NONE,
    SORT_DIRECTION_DESC,
    SORT_DIRECTION_ASC,
} from '../config/constants';

function resolveFilterList({ name, options }, filter) {
    if (options.filterType === SORT_FILTER_TYPE_CUSTOM) {
        if (options.filterOptions && options.filterOptions.names) {
            const columnFilters = [];
            options.filterOptions.names.forEach((filterValue, valueKey) => {
                const value = get(filter, `${name}.${filterValue}`, null);
                if (value !== null) {
                    columnFilters[valueKey] = value;
                }
            });
            return columnFilters;
        }
    }
    return filter[name] ? [filter[name]] : [];
}

function resolveSort({ name, options }, filter) {
    if (options.sort === false) {
        return { sortDirection: SORT_DIRECTION_NONE };
    }

    const sortParts = (filter.sort && filter.sort.split(',')) || [];
    for (const part of sortParts) {
        if (part.indexOf('-') === 0 && part.indexOf(name) === 1) {
            return { sortDirection: SORT_DIRECTION_DESC };
        }
        if (part.indexOf(name) === 0) {
            return { sortDirection: SORT_DIRECTION_ASC };
        }
    }
    return { sortDirection: SORT_DIRECTION_NONE };
}

function resolveOptionsFromFilter(column, filter) {
    return {
        filterList: resolveFilterList(column, filter),
        ...resolveSort(column, filter),
    };
}

export default resolveOptionsFromFilter;
