import { captureEvent, Severity } from '@sentry/browser';
import currentUserSettingsProvider from '%paysera_admin_frontend/js/common/service/currentUserSettingsProvider';

const ENVIRONMENT = environment_config.environment;

class Logger {
    fatal(message, params = {}) {
        this.log(Severity.Fatal, message, params);
    }

    error(message, params = {}) {
        this.log(Severity.Error, message, params);
    }

    warning(message, params = {}) {
        this.log(Severity.Warning, message, params);
    }

    info(message, params = {}) {
        this.log(Severity.Info, message, params);
    }

    debug(message, params = {}) {
        this.log(Severity.Debug, message, params);
    }

    log(level, message, params) {
        let extra = params;
        const additional = {};

        if (params.response !== undefined && params.request !== undefined) {
            extra = {
                status: params.response.status,
                data: params.response.data,
                mute: params.mute,
            };
            additional.request = {
                url: params.response.url,
                method: params.response.method,
            };
        }

        if (ENVIRONMENT === 'dev') {
            if (level === Severity.Fatal || level === Severity.Error) {
                console.error(message, params);
            }
            if (level === Severity.Warning) {
                console.warn(message, params);
            }
            if (level === Severity.Info) {
                console.info(message, params);
            }
            if (level === Severity.Debug) {
                console.debug(message, params);
            }
        }

        captureEvent({
            message,
            level,
            extra,
            user: {
                id: currentUserSettingsProvider.get('user_id'),
                username: currentUserSettingsProvider.getUserShortName(),
                email: currentUserSettingsProvider.get('email'),
            },
            ...additional,
        });
    }
}

export default new Logger();
