import React, { useCallback, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import hasCookieConsent from '../../service/hasCookieConsent';
import Content from '../Content';
import CookieSettingsButton from '../CookieSettingsButton';

function CookieAgreementCard(props) {
    const [open, setOpen] = useState(false);
    const closeCard = useCallback(() => setOpen(false), []);
    const openCard = useCallback(() => setOpen(true), []);

    useEffect(() => setOpen(!hasCookieConsent()), []);

    return (
        <React.Fragment>
            {open && (
                <Card>
                    <Content {...props} closeAgreementCard={closeCard} />
                </Card>
            )}
            <CookieSettingsButton openAgreementCard={openCard} />
        </React.Fragment>
    );
}

export default React.memo(CookieAgreementCard);
