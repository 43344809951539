import { createMuiTheme } from '@material-ui/core';
import resolveTextDirection from '%app/js/common/components/material/service/resolveTextDirection';

const tableTheme = theme => createMuiTheme({
    ...theme,
    direction: resolveTextDirection(),
    overrides: {
        ...theme.overrides,
        MUIDataTableBodyCell: {
            ...theme.overrides?.MUIDataTableBodyCell || {},
            stackedCommon: {
                ...theme.overrides?.MUIDataTableBodyCell?.stackedCommon || {},
                [theme.breakpoints.down('sm')]: {
                    height: theme.spacing(4),
                    padding: theme.spacing(0.5),
                    fontSize: theme.spacing(2) - 2,
                    paddingLeft: theme.spacing(2),
                },
            },
        },
        MuiTableCell: {
            ...theme.overrides?.MuiTableCell || {},
            root: {
                ...theme.overrides?.MuiTableCell?.root || {},
                padding: theme.spacing(1),
                paddingLeft: theme.spacing(2),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        MUIDataTableSelectCell: {
            ...theme.overrides?.MUIDataTableSelectCell || {},
            root: {
                ...theme.overrides?.MUIDataTableSelectCell?.root || {},
                [theme.breakpoints.down('sm')]: {
                    '& > div': {
                        flexDirection: 'column',
                    },
                },
            },
        },
        MUIDataTableFilterList: {
            ...theme.overrides?.MUIDataTableFilterList || {},
            root: {
                ...theme.overrides?.MUIDataTableFilterList?.root || {},
                [theme.breakpoints.down('sm')]: {
                    paddingBottom: theme.spacing(1),
                },
            },
        },
        MUIDataTableHeadCell: {
            ...theme.overrides?.MUIDataTableHeadCell || {},
            fixedHeaderCommon: {
                ...theme.overrides?.MUIDataTableHeadCell?.fixedHeaderCommon || {},
                backgroundColor: 'inherit',
            },
        },
    },
});

export default tableTheme;
