const DIRECTION_RTL = 'rtl';
const DIRECTION_LTR = 'ltr';
const DEFAULT_FONT_FAMILIES = ['"Roboto"', 'Helvetica', 'Arial', 'sans-serif'];
const RTL_FONT_FAMILY = 'Amiri';

export {
    DIRECTION_LTR,
    DIRECTION_RTL,
    DEFAULT_FONT_FAMILIES,
    RTL_FONT_FAMILY,
};
