import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Hidden } from '@material-ui/core';

function PrimarySecondaryText({ primary, secondary }) {
    return (
        <React.Fragment>
            <Typography variant="body2" display="block" noWrap>{primary}</Typography>
            {secondary && (
                <Hidden smDown>
                    <Typography variant="caption" color="textSecondary" display="block">{secondary}</Typography>
                </Hidden>
            )}
        </React.Fragment>
    );
}

PrimarySecondaryText.propTypes = {
    primary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PrimarySecondaryText.defaultProps = {
    primary: null,
    secondary: null,
};

export default React.memo(PrimarySecondaryText);
