import localizationStorage from '%app/js/common/services/localizationStorage';
import { create } from 'jss';
import { jssPreset } from '@material-ui/core';
import rtl from 'jss-rtl';

function configureTextDirection() {
    const isRtlLocale = localizationStorage.isCurrentLanguageRtl();

    let { plugins } = jssPreset();

    if (isRtlLocale) {
        plugins = [...plugins, rtl()];
    }

    return create({ plugins });
}

export default configureTextDirection;
