const PRIMARY = '#000099';
const SECONDARY = '#3399ff';
const SUCCESS = '#298658';
const WARNING = '#FAB415';
const ERROR_BACKGROUND = '#ffebec';
const BOOTSTRAP_PRIMARY = '#3399ff';
const BOOTSTRAP_SECONDARY = '#000099';

export {
    PRIMARY,
    SECONDARY,
    SUCCESS,
    WARNING,
    ERROR_BACKGROUND,
    BOOTSTRAP_PRIMARY,
    BOOTSTRAP_SECONDARY,
};
