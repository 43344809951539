import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Translator from '%app/js/common/services/translator';
import logger from '%app/js/common/services/Logger';

function CookieSettingsButton({ openAgreementCard, classes }) {
    const mountNodeId = 'cookie-settings-react-portal';
    const mountNode = document.getElementById(mountNodeId);
    if(!mountNode) {
        logger.error(`Element with ID ${mountNodeId} was not found in the DOM`);
        return null;
    }

    return (
        ReactDOM.createPortal(
            <button
                className={classes.button}
                type="button"
                onClick={() => openAgreementCard()}
            >
                {Translator.trans('service_agreement_cookies.button.cookie_settings')}
            </button>,
            mountNode,
        )
    );
}

CookieSettingsButton.propTypes = {
    classes: PropTypes.shape({
        button: PropTypes.string,
    }).isRequired,
    openAgreementCard: PropTypes.func.isRequired,
};

export default React.memo(CookieSettingsButton);
