const SORT_DIRECTION_NONE = 'none';
const SORT_DIRECTION_ASC = 'asc';
const SORT_DIRECTION_DESC = 'desc';
const SORT_FILTER_TYPE_CUSTOM = 'custom';
const DISPLAY_TYPE_EXCLUDED = 'excluded';

export {
    SORT_DIRECTION_ASC,
    SORT_DIRECTION_DESC,
    SORT_DIRECTION_NONE,
    SORT_FILTER_TYPE_CUSTOM,
    DISPLAY_TYPE_EXCLUDED,
};
