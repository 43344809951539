import React, { useState } from 'react';
import {
    Box,
    Tab,
    Tabs as MuiTabs,
    Typography,
} from '@material-ui/core';
import TabPanel from '../TabPanel';
import Translator from '%app/js/common/services/translator';
import CookiesTable from '../CookiesTable';
import {
    getMarketingCookiesData,
    getNecessaryCookiesData,
    getStatisticsCookiesData,
} from '../CookiesTable/tableData';

function Tabs() {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, currentTab) => {
        setSelectedTab(currentTab);
    };

    return (
        <React.Fragment>
            <MuiTabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                aria-label="full width tabs"
            >
                <Tab
                    label={(
                        <Typography variant="body2">
                            {Translator.trans('service_agreement_cookies.category.necessary')}
                        </Typography>
                    )}
                    id="cookies-category-tab-0"
                    aria-controls="cookies-category-tabpanel-0"
                />
                <Tab
                    label={(
                        <Typography variant="body2">
                            {Translator.trans('service_agreement_cookies.category.statistics')}
                        </Typography>
                    )}
                    id="cookies-category-tab-1"
                    aria-controls="cookies-category-tabpanel-1"
                />
                <Tab
                    label={(
                        <Typography variant="body2">
                            {Translator.trans('service_agreement_cookies.category.marketing')}
                        </Typography>
                    )}
                    id="cookies-category-tab-2"
                    aria-controls="cookies-category-tabpanel-2"
                />
            </MuiTabs>
            <TabPanel value={selectedTab} index={0}>
                <Box p={1}>
                    <Typography variant="body2">
                        {Translator.trans('service_agreement_cookies.category.necessary.body')}
                    </Typography>
                </Box>
                <CookiesTable data={getNecessaryCookiesData()} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <Box p={1}>
                    <Typography variant="body2">
                        {Translator.trans('service_agreement_cookies.category.statistics.body')}
                    </Typography>
                </Box>
                <CookiesTable data={getStatisticsCookiesData()} />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <Box p={1}>
                    <Typography variant="body2">
                        {Translator.trans('service_agreement_cookies.category.marketing.body')}
                    </Typography>
                </Box>
                <CookiesTable data={getMarketingCookiesData()} />
            </TabPanel>
        </React.Fragment>
    );
}

export default React.memo(Tabs);
