import { createMuiTheme } from '@material-ui/core/styles';
import Color from 'color';
import {
    PRIMARY,
    SECONDARY,
    SUCCESS,
    WARNING,
    ERROR_BACKGROUND,
    BOOTSTRAP_PRIMARY,
    BOOTSTRAP_SECONDARY,
} from './config/colors';
import resolveTextDirection from '%app/js/common/components/material/service/resolveTextDirection';
import getFontFamilies from '%evp_theme/brandbook/material/service/getFontFamilies';

const successColor = Color(SUCCESS);
const warningColor = Color(WARNING);
const primaryColor = Color(PRIMARY);
const bootstrapPrimaryColor = Color(BOOTSTRAP_PRIMARY);
const bootstrapSecondaryColor = Color(BOOTSTRAP_SECONDARY);

const theme = {
    direction: resolveTextDirection(),
    typography: {
        useNextVariants: true,
        fontFamily: getFontFamilies(),
    },
    palette: {
        primary: {
            main: primaryColor.hex(),
            desaturatedLight: primaryColor.lighten(0.70).desaturate(0.60).hex(),
        },
        secondary: {
            main: SECONDARY,
        },
        success: {
            main: successColor.hex(),
            light: successColor.lighten(0.5).hex(),
            dark: successColor.darken(0.5).hex(),
            contrastText: (successColor.isLight() ? '#333333' : '#ffffff'),
        },
        warning: {
            main: warningColor.darken(0.15).hex(),
            light: warningColor.lighten(0.5).hex(),
            dark: warningColor.darken(0.5).hex(),
            contrastText: (warningColor.isLight() ? '#333333' : '#ffffff'),
        },
        errorBackground: {
            main: ERROR_BACKGROUND,
        },
        background: {
            default: '#efefef',
        },
    },
    props: {
        MuiGrid: {
            spacing: 2,
        },
        MuiSwitch: {
            color: 'secondary',
        },
        MuiRadio: {
            color: 'secondary',
        },
        MuiButton: {
            color: 'primary',
        },
        MuiFab: {
            color: 'primary',
        },
        MuiTypography: {
            color: 'inherit',
        },
    },
};

const bootstrapAwareTheme = createMuiTheme({
    ...theme,
    palette: {
        ...theme.palette,
        primary: {
            main: bootstrapPrimaryColor.hex(),
            desaturatedLight: bootstrapPrimaryColor.lighten(0.70).desaturate(0.60).hex(),
            dark: bootstrapPrimaryColor.darken(0.175).hex(),
            contrastText: '#ffffff',
        },
        secondary: {
            main: bootstrapSecondaryColor.hex(),
        },
    },
    props: {
        ...theme.props,
        MuiButton: {
            color: 'secondary',
        },
        MuiSlider: {
            color: 'secondary',
        },
    },
    overrides: {
        ...theme.overrides,
        MuiButton: {
            root: {
                borderRadius: 'unset',
                textTransform: 'unset',
            },
            contained: {
                boxShadow: 'unset',
            },
            textSecondary: {
                textDecoration: 'underline',
            },
        },
        MuiTab: {
            root: {
                textTransform: 'unset',
            },
        },
    },
    typography: {
        ...theme.typography,
        fontSize: 24,
    },
});

export default createMuiTheme(theme);

export {
    bootstrapAwareTheme,
};
