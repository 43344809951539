import { withStyles } from '@material-ui/core';
import CookieAgreementCard from './CookieAgreementCard';

const styles = ({
    spacing,
    shadows,
    palette,
    breakpoints,
}) => ({
    paper: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 16,
        maxHeight: '100vh',
        overflowY: 'auto',
        backgroundColor: palette.background.paper,
        boxShadow: shadows[5],
        padding: spacing(2),
        [breakpoints.up('lg')]: {
            maxHeight: '99.5vh',
        },
    },
    hiddenCollapse: {
        display: 'none',
    },
});

export default withStyles(styles)(CookieAgreementCard);
