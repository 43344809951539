import Translator from '%app/js/common/services/translator';

const getNecessaryCookiesData = () => [
    {
        name: '_cfduid',
        provider: 'paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._cfduid'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._cfduid'),
    },
    {
        name: 'CookieConsent',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.cookie_consent'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.cookie_consent'),
    },
    {
        name: 'SESS#',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.sess'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.sess'),
    },
    {
        name: 'sessionID',
        provider: 'tickets.paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.session_id'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.session_id'),
    },
];

export default getNecessaryCookiesData;
