import { withStyles } from '@material-ui/core';
import CookiesTable from './CookiesTable';

const styles = () => ({
    colPurpose: {
        whiteSpace: 'normal',
        height: 'auto',
    },
});

export default withStyles(styles)(CookiesTable);
