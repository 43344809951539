import React from 'react';
import PropTypes from 'prop-types';
import MaterialDataTable from '%app/js/common/components/material/DataTable/MaterialDataTable';
import Translator from '%app/js/common/services/translator';
import { Typography } from '@material-ui/core';

function CookiesTable({ data, classes }) {
    const options = {
        search: false,
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        filter: false,
        pagination: false,
        sort: false,
        elevation: 0,
    };

    const columns = [
        {
            label: Translator.trans('service_agreement_cookies.table.name'),
            name: 'name',
            options: {},
        },
        {
            label: Translator.trans('service_agreement_cookies.table.provider'),
            name: 'provider',
            options: {},
        },
        {
            label: Translator.trans('service_agreement_cookies.table.expiry'),
            name: 'expiry',
            options: {},
        },
        {
            label: Translator.trans('service_agreement_cookies.table.purpose'),
            name: 'purpose',
            options: {
                customBodyRender: value => <Typography variant="body2" noWrap={false}>{value}</Typography>,
                setCellProps: () => ({ className: classes.colPurpose }),
            },
        },
    ];

    return (
        <MaterialDataTable
            title=""
            filter={{}}
            data={data}
            onFilterChange={() => {}}
            options={options}
            columns={columns}
        />
    );
}

CookiesTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.shape({
        colPurpose: PropTypes.string,
    }).isRequired,
};

export default React.memo(CookiesTable);
