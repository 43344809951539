import { withStyles } from '@material-ui/core';
import CookieSettingsButton from './CookieSettingsButton';

const styles = () => ({
    button: {
        padding: 'inherit',
        backgroundColor: 'inherit',
        border: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

export default withStyles(styles)(CookieSettingsButton);
