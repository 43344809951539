import { isWidthDown } from '@material-ui/core/withWidth';
import { DISPLAY_TYPE_EXCLUDED } from '../config/constants';

function resolveResponsiveDisplayOption({ options: { display, filter }, label }, width) {
    if (display !== undefined) {
        if (
            display === DISPLAY_TYPE_EXCLUDED
            && label !== null
            && filter === undefined
            && isWidthDown('sm', width)
        ) {
            return { display: true };
        }
        return { display };
    }

    return {};
}

export default resolveResponsiveDisplayOption;
