import {
    CATEGORY_NECESSARY,
    CATEGORY_STATISTICS,
    CATEGORY_MARKETING,
} from '../config/constants';
import hasCookieCategoryConsent from './hasCookieCategoryConsent';

const hasCookieConsent = () => hasCookieCategoryConsent(CATEGORY_NECESSARY)
    && hasCookieCategoryConsent(CATEGORY_STATISTICS)
    && hasCookieCategoryConsent(CATEGORY_MARKETING);

export default hasCookieConsent;
