import React, { useState } from 'react';
import { isEqual } from 'lodash/lang';
import PropTypes from 'prop-types';
import resolveFilterFromState from './service/resolveFilterFromState';
import resolveColumnsFromState from './service/resolveColumnsFromState';
import resolveOptionsFromFilter from './service/resolveOptionsFromFilter';
import resolveResponsiveDisplayOption from './service/resolveResponsiveDisplayOption';
import resolveCustomBodyRenderer from './service/resolveCustomBodyRenderer';
import TranslatedTable from './TranslatedTable';

function MaterialDataTable({
    title,
    data,
    columns,
    options,
    onFilterChange,
    filter,
    total,
    searchPlaceholder,
    width,
    ...rest
}) {
    const [serverFilterList, setServerFilterList] = useState([]);

    return (
        <TranslatedTable
            title={title}
            data={data}
            columns={columns.map((column, index) => ({
                ...column,
                options: {
                    ...resolveOptionsFromFilter(column, filter),
                    ...resolveCustomBodyRenderer(column),
                    ...column.options,
                    ...resolveResponsiveDisplayOption(column, width),
                    filterList: serverFilterList[index] || column.options.filterList,
                },
            }))}
            options={{
                count: total,
                searchPlaceholder,
                onTableChange: (action, state) => {
                    if (!isEqual(state.filterList, serverFilterList)) {
                        setServerFilterList(state.filterList);
                    }
                    const nextFilter = resolveFilterFromState(state);
                    if (!isEqual(filter, nextFilter)) {
                        const nextColumns = resolveColumnsFromState(columns, state.columns);
                        onFilterChange(nextFilter, nextColumns);
                    }
                },
                serverSideFilterList: serverFilterList,
                rowsPerPage: filter.limit,
                page: filter.offset && (filter.offset / filter.limit) - 1,
                searchText: filter.query,
                rowsPerPageOptions: [10, 20, 50, 100],
                ...options,
            }}
            {...rest}
        />
    );
}

MaterialDataTable.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    options: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    total: PropTypes.number,
    searchPlaceholder: PropTypes.string,
    width: PropTypes.string.isRequired,
};

MaterialDataTable.defaultProps = {
    total: null,
    searchPlaceholder: null,
};

export default React.memo(MaterialDataTable);
