import Translator from '%app/js/common/services/translator';

const getMarketingCookiesData = () => [
    {
        name: '_fbp',
        provider: 'paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._fbp'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._fbp'),
    },
    {
        name: '_fbc',
        provider: 'paysera.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry._fbc'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose._fbc'),
    },
    {
        name: 'ads/ga-audiences',
        provider: 'google.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.ads_ga_audiences'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.ads_ga_audiences'),
    },
    {
        name: 'fr',
        provider: 'facebook.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.fr'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.fr'),
    },
    {
        name: 'tr',
        provider: 'facebook.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.tr'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.tr'),
    },
    {
        name: '1P_JAR',
        provider: 'google.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.1p_jar'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.1p_jar'),
    },
    {
        name: 'NID',
        provider: 'google.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.nid'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.nid'),
    },
    {
        name: 'ANID',
        provider: 'google.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.anid'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.anid'),
    },
    {
        name: 'CONSENT',
        provider: 'google.com',
        expiry: Translator.trans('service_agreement_cookies.table.expiry.consent'),
        purpose: Translator.trans('service_agreement_cookies.table.purpose.consent'),
    },
];

export default getMarketingCookiesData;
