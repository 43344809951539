import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import tableTheme from '../config/theme';
import MaterialDataTable from './MaterialDataTable';

function StyledTable(props) {
    return (
        <MuiThemeProvider theme={tableTheme}>
            <MaterialDataTable {...props} />
        </MuiThemeProvider>
    );
}

export default React.memo(StyledTable);
