import React from 'react';
import PrimarySecondaryText from '%app/js/common/components/material/DataTable/PrimarySecondaryText';
import { DISPLAY_TYPE_EXCLUDED } from '%app/js/common/components/material/DataTable/MaterialDataTable/config/constants';

function resolveCustomBodyRenderer({ options }) {
    if (options?.display === DISPLAY_TYPE_EXCLUDED) {
        return {};
    }

    return {
        customBodyRender: value => <PrimarySecondaryText primary={value} />,
    };
}

export default resolveCustomBodyRenderer;
